import { WixCodeApiFactoryArgs } from '@wix/thunderbolt-symbols'
import { navigationNamespace } from '../symbols'
import type {
	NativeMobileWixCodeSdkHandlers,
	NativeMobileWixCodeSdkWixCodeApi,
	NativeMobileWixCodeSdkSiteConfig,
} from '../types'

/**
 * This is SDK Factory.
 * Expose your Velo API
 */
export function NativeMobileSdkFactory({
	// featureConfig,
	handlers,
}: WixCodeApiFactoryArgs<NativeMobileWixCodeSdkSiteConfig, unknown, NativeMobileWixCodeSdkHandlers>): {
	[navigationNamespace]: NativeMobileWixCodeSdkWixCodeApi
} {
	return {
		[navigationNamespace]: {
			dismissAllModals: handlers.dismissAllModals,
			openURL: handlers.openURL,
		},
	}
}
